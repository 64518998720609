import { createRouter, createWebHashHistory } from 'vue-router'
import constant from "@/constant"
import Layout from "@/layout/Layout"
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'Login',
    id:"1",
    meta: {title: "用户登录", requireAuth: false},
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/main',
    id:"2",
    component: Layout,
    name: "工作台",
    icon: "el-icon-s-home",
    meta: {title: constant.title, requireAuth: true},
    redirect: '/home',
    children: [
        {
            path: "/home",
            id:"3",
            name: "工作台",
            icon: "el-icon-s-home",
            meta: {title: "工作台", requireAuth: true},
            component: () => import('@/views/Home.vue')
        }
    ]
  },
  {
    path: '/orderlist',
    id:"4",
    icon: "el-icon-s-home",
    name: "订单列表",
    meta: {title: "订单列表", requireAuth: true},
    component: () => import('@/views/Orderlist.vue')
  },
  {
    path:"/partners",
    id:"5",
    name:"分销店铺 ",
    meta:{title:"分销店铺 ",requireAuth:true},
    component:()=>import('@/views/Partners.vue')
  },
  {
    path:"/editpartners",
    id:"6",
    name:"编辑店铺 ",
    meta:{title:"编辑店铺 ",requireAuth:true},
    component:()=>import('@/views/editpartners.vue')
  },
  {
    path: "/products",
    id:"7",
    name: "商品管理 ",
    meta: {title: "商品管理 ", requireAuth: true},
    component: () => import('@/views/Products.vue')
  },
  {
    path: "/salesmans",
    id:"8",
    name: "业务员统计2",
    meta: {title: "业务员统计2", requireAuth: true,},
    component: () => import('@/views/Salesmans.vue')
  },
  {
    path: "/blacklist2",
    id:"83",
    name: "黑名单2",
    meta: {title: "黑名单2", requireAuth: true,},
    component: () => import('@/views/blacklist2.vue')
  },
  {
    path: "/addpdts",
    id:"9",
    name: "新增商品 ",
    meta: {title: "新增商品 ", requireAuth: true, show: false},
    component: () => import('@/views/AddProducts.vue')
  },
  {
    path: "/addplans",
    id:"10",
    name: "新增套餐 ",
    meta: {title: "新增套餐 ", requireAuth: true},
    component: () => import('@/views/AddPlanv2s.vue')
  },
  {
    path: '/main2',
    id:"11",
    component: Layout,
    name:"个人资料",
    meta: {title: constant.title, requireAuth: true},
    redirect:"/personinfo",
    children: [
        {
          path:"/personinfo",
          id:"12",
          name:"个人资料",
          icon: "el-icon-user",
          meta: {title: "个人资料", requireAuth: true},
          component: () => import('@/views/PersonInfo.vue')
        }
    ]
  },
  // {
  //   path: '/merchantinfo',
  //   id:"13",
  //   component: Layout,
  //   name:"商铺资料",
  //   meta: {title: constant.title, requireAuth: true},
  //   redirect:"/merchantinfo",
  //   children: [
  //       {
  //         path:"/merchantinfo",
  //         id:"14",
  //         name:"商铺资料",
  //         icon: "el-icon-user",
  //         meta: {title: "个人资料", requireAuth: true},
  //         component: () => import('@/views/MerchantInfo.vue')
  //       }
  //   ]
  // },
  {
    path: '/permission',
    id:"15",
    component: Layout,
    name:'权限管理',
    icon: "el-icon-user",
    meta: {title: '权限管理', requireAuth: true},
    redirect: '/operator',
    children: [
        // {
        //     path: "/role",
        //     id:"16",
        //     name: "账号角色管理",
        //     meta: {title: "账号角色管理", requireAuth: true, show: true},
        //     component: () => import('@/views/Role.vue')
        // },
        {
          path: "/operator",
          id:"17",
          name: "人员列表",
          icon: "el-icon-user",
          meta: {title: "人员列表", requireAuth: true, show: true},
          component: () => import('@/views/Operator.vue')
        },
        // {
        //   path: "/blacklist",
        //   id:"18",
        //   name: "黑名单",
        //   meta: {title: "黑名单", requireAuth: true, show: true},
        //   component: () => import('@/views/Blacklist.vue')
        // },
        // {
        //   path: "/salesman",
        //   id:"19",
        //   name: "业务员统计",
        //   meta: {title: "业务员统计", requireAuth: true, show: true},
        //   component: () => import('@/views/SalesMan.vue')
        // },
        // {
        //   path: "/deduct",
        //   id:"20",
        //   name: "业务员订单详情",
        //   meta: {title: "业务员订单详情", requireAuth: true},
        //   component: () => import('@/views/deduct.vue')
        // },
    ]
  },
  // {
  //   path:'/merchant',
  //   id:"21",
  //   name:'商户管理',
  //   component: Layout,
  //   icon: "el-icon-s-custom",
  //   meta: {title: "商户管理", requireAuth: true},
  //   children:[
  //     {
  //       path: "/merchantlist",
  //       id:"22",
  //       name: "商户列表",
  //       meta: {title: "商户列表", requireAuth: true, show: true},
  //       component: () => import('@/views/Merchant.vue')
  //     },
  //     {
  //       path: "/addmerchant",
  //       id:"23",
  //       name: "新增商户",
  //       meta: {title: "新增商户", requireAuth: true, show: false},
  //       component: () => import('@/views/AddMerchant.vue')
  //     },
  //     {
  //       path: "/addagent",
  //       id:"24",
  //       name: "新增代理商",
  //       meta: {title: "新增代理商", requireAuth: true, show: false},
  //       component: () => import('@/views/Addagent.vue')
  //     },
  //     {
  //       path:"/merchantnew",
  //       id:"25",
  //       name:"商家入驻",
  //       meta:{title:"商家入驻",requireAuth:true,show:true},
  //       component:()=>import('@/views/Merchantruzhu.vue')
  //     },
  //     {
  //       path:"/partner",
  //       id:"26",
  //       name:"分销店铺",
  //       meta:{title:"分销店铺",requireAuth:true,show:true},
  //       component:()=>import('@/views/Partner.vue')
  //     },
      
  //     {
  //       path:"/editpartner",
  //       id:"27",
  //       name:"编辑店铺",
  //       meta:{title:"编辑店铺",requireAuth:true},
  //       component:()=>import('@/views/editpartner.vue')
  //     }
  //   ]
  // },
  {
    path:'/product',
    id:"28",
    name:'商品管理',
    component: Layout,
    icon: "el-icon-goods",
    meta: {title: "商品管理", requireAuth: true},
    children:[
      {
        path: "/pdt",
        id:"29",
        name: "商品管理", 
        meta: {title: "商品管理", requireAuth: true, show: true},
        component: () => import('@/views/Product.vue')
      },
      // {
      //   path:"/productdetail",
      //   id:"30",
      //   name:"商品详情页管理",
      //   meta: {title: "商品详情页管理", requireAuth: true, show: false},
      //   component: () => import('@/views/ProductDetail.vue')
      // },
      {
        path: "/planlist",
        id:"31",
        name: "套餐管理",
        meta: {title: "套餐管理", requireAuth: true, show: true},
        component: () => import('@/views/Plan.vue')
      },
      {
        path: "/stock",
        id:"32",
        name: "库存管理",
        meta: {title: "库存管理", requireAuth: true, show: true},
        component: () => import('@/views/Stock.vue')
      },
      
      // {
      //   path: "/addplan",
      //   id:"33",
      //   name: "新增套餐",
      //   meta: {title: "新增套餐", requireAuth: true, show: false},
      //   component: () => import('@/views/AddPlanv2.vue')
      // },
      // {
      //   path: "/editplan",
      //   id:"34",
      //   name: "编辑套餐",
      //   meta: {title: "编辑套餐", requireAuth: true},
      //   component: () => import('@/views/EditPlan.vue')
      // },
      // {
      //   path: "/addpdt",
      //   id:"35",
      //   name: "新增商品",
      //   meta: {title: "新增商品", requireAuth: true, show: false},
      //   component: () => import('@/views/AddProduct.vue')
      // },
    ]
  },
  // {
  //   path:'/info',
  //   id:"36",
  //   name:'基本信息管理',
  //   component: Layout,
  //   icon: "el-icon-eleme",
  //   meta: {title: "基本信息管理", requireAuth: true},
  //   children:[
  //     {
  //       path: "/category",
  //       id:"37",
  //       name: "品类管理",
  //       meta: {title: "品类管理", requireAuth: true, show: true},
  //       component: () => import('@/views/Category.vue')
  //     },
  //     {
  //       path: "/brand",
  //       id:"38",
  //       name: "品牌管理",
  //       meta: {title: "品牌管理", requireAuth: true, show: true},
  //       component: () => import('@/views/Brand.vue')
  //     },
  //     {
  //       path: "/model",
  //       id:"39",
  //       name: "型号管理",
  //       meta: {title: "型号管理", requireAuth: true, show: true},
  //       component: () => import('@/views/Model.vue')
  //     },
  //     {
  //       path: "/param",
  //       id:"40",
  //       name: "参数管理",
  //       meta: {title: "参数管理", requireAuth: true, show: true},
  //       component: () => import('@/views/Param.vue')
  //     },
  //   ]
  // },
  {
    path:'/information',
    id:"41",
    name: "通用信息管理",
    component: Layout,
    icon: "el-icon-info",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/template",
          id:"42",
          name: "模板管理",
          meta: {title: "模板管理", requireAuth: true, show: true},
          component: () => import('@/views/Template.vue')
      },
      {
        path: "/notice",
        id:"43",
        name: "公告管理",
        meta: {title: "公告管理", requireAuth: true, show: true},
        component: () => import('@/views/Notice.vue')
      },
      // {
      //   path: "/guarant",
      //   id:"44",
      //   name: "保障服务",
      //   meta: {title: "保障服务", requireAuth: true, show: true},
      //   component: () => import('@/views/Guarant.vue')
      // },
      // {
      //   path: "/addguarant",
      //   id:"45",
      //   name: "编辑保障服务",
      //   meta: {title: "编辑保障服务", requireAuth: true, show: false},
      //   component: () => import('@/views/AddGuarant.vue')
      // }
    ]
  },
  {
    path:'/order',
    id:"46",
    name: "订单管理",
    component: Layout,
    icon: "el-icon-document-copy",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/order",
          id:"47",
          name: "订单管理",
          meta: {title: "订单管理", requireAuth: true, show: true},
          component: () => import('@/views/Order.vue')
      },
    //   {
    //     path: "/orderdetail",
    //     id:"48",
    //     name: "订单详情",
    //     meta: {title: "订单详情", requireAuth: true},
    //     component: () => import('@/views/Orderdetail.vue')
    // },
      {
        path: "/buyout",
        id:"49",
        name: "申请买断管理",
        meta: {title: "申请买断管理", requireAuth: true, show: true},
        component: () => import('@/views/Buyout.vue')
      },
      // {
      //   path: "/overdue",
      //   id:"50",
      //   name: "逾期订单管理",
      //   meta: {title: "逾期订单管理", requireAuth: true, show: false},
      //   component: () => import('@/views/Overdue.vue')
      // },
      
      // {
      //   path: "/associate",
      //   id:"51",

      //   name: "关联管理",
      //   meta: {title: "关联管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Associate.vue')
      // },
      
      // {
      //   path: "/addassociate",
      //   id:"52",

      //   name: "新建关联",
      //   meta: {title: "新建关联", requireAuth: true, show: false},
      //   component: () => import('@/views/AddAssociate.vue')
      // },
      // {
      //   path: "/allocate",
      //   id:"53",

      //   name: "调拨管理",
      //   meta: {title: "调拨管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Allocate.vue')
      // }
    ]
  },
  
  {
    path:'/finance',
    id:"54",

    name: "财务管理",
    component: Layout,
    icon: "el-icon-coin",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/bill",
          id:"55",

          name: "交易流水",
          meta: {title: "交易流水", requireAuth: true, show: true},
          component: () => import('@/views/Bill.vue')
      },
      // {
      //   path: "/gather",
      //   name: "账单管理",
      //   meta: {title: "账单管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Gather.vue')
      // },

      // {
      //   path: "/refund",
      //   id:"56",

      //   name: "退款管理",
      //   meta: {title: "退款管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Refund.vue')
      // },
      {
        path: "/cashout",
        id:"57",

        name: "提现管理",
        meta: {title: "提现管理", requireAuth: true, show: true},
        component: () => import('@/views/Cashout.vue')
      },
      {
        path:'/settle',
        id:"58",

        name:'结算管理',
        meta:{title:'结算管理',requireAuth: true, show: true},
        component: ()=> import('@/views/Settle.vue')
      },
      {
        path:'/recharge',
        id:"59",

        name:'打款管理',
        meta:{title:'打款管理',requireAuth: true, show: true},
        component: ()=> import('@/views/recharge.vue')
      },
      {
        path:'/rechargedetail',
        id:"60",

        name:'充值管理',
        meta:{title:'充值管理',requireAuth: true, show: true},
        component: ()=> import('@/views/rechargedetail.vue')
      },
      // {
      //   path:'/rechargedetail',
      //   id:"61",

      //   name:'充值明细',
      //   meta:{title:'充值明细',requireAuth: true},
      //   component: ()=> import('@/views/rechargedetail.vue')
      // },
      // {
      //   path:'/invoiceManager',
      //   id:"62",

      //   name:'发票管理',
      //   meta:{title:'发票管理',requireAuth: true, show: true},
      //   component: ()=> import('@/views/InvoiceManager.vue')
      // },
      {
        path:'/invoice',
        id:"63",

        name:'申请发票',
        meta:{title:'申请发票',requireAuth: true, show: true},
        component: ()=> import('@/views/Invoice.vue')
      },
    ]
  },
  // {
  //   path: '/setting',
  //   id:"64",

  //   component: Layout,
  //   name:'前端配置',
  //   icon: "el-icon-setting",
  //   meta: {title: constant.title, requireAuth: true},
    
  //   children: [
      
  //     {
  //       path: '/page/index',
  //       id:"65",

  //       name: '首页管理',
        
  //       meta: { title: '首页管理', requireAuth: true, channelid: '2',show: true },
  //       component: () => import("@/views/page/index.vue"),
        
  //     },
      
  //     {
  //       path: '/page/category',
  //       id:"66",

  //       name: '分类页管理',
        
  //       meta: { title: '分类页管理', requireAuth: true,show: true },
  //       component: () => import("@/views/page/category.vue"),
        
  //     },
      
  //     {
  //       path: '/page/my',
  //       id:"67",

  //       name: '个人页管理',
        
  //       meta: { title: '个人页管理', requireAuth: true,show: true },
  //       component: () => import("@/views/page/my.vue"),
        
  //     },
      
  //     {
  //       path: '/page/goodsmanage',
  //       id:"68",

  //       name: '前端商品管理',
        
  //       meta: { title: '前端商品管理', requireAuth: true },
  //       component: () => import("@/views/page/goods_manage.vue"),
        
  //     },
      
  //     {
  //       path: '/page/contentmanage',
  //       id:"69",

  //       name: '区域内容管理',
        
  //       meta: { title: '区域内容管理', requireAuth: true },
  //       component: () => import("@/views/page/content_manage.vue"),
       
  //     },
      
  //     {
  //       path: '/page/menumanage',
  //       id:"70",

  //       name: '橱窗管理',
        
  //       meta: { title: '橱窗管理', requireAuth: true },
  //       component: () => import("@/views/page/menu_manage.vue"),
        
  //     }
  //   ]
  // },
  // {
  //   path: '/activity',
  //   id:"71",

  //   component: Layout,
  //   name:'活动管理',
  //   icon: "el-icon-star-off",
  //   meta: {title: constant.title, requireAuth: true},
    
  //   children: [
        
  //       {
  //         path: '/page/activity',
  //       id:"72",

  //         name: '活动页管理',
          
  //         meta: { title: '活动页管理', requireAuth: true, channelid: '2' ,show: true},
  //         component: () => import("@/views/page/activity_list.vue"),
          
  //       },
  //       {
  //         path: '/page/actdetail',
  //       id:"73",

  //         name: '活动页设置',
          
  //         meta: { title: '活动页设置', requireAuth: true },
  //         component: () => import("@/views/page/activity_detail.vue"),
          
  //       },
  //       {
  //         path: "/coupon",
  //       id:"74",

  //         name: "优惠券管理",
          
  //         meta: {title: "优惠券管理", requireAuth: true, show: true},
  //         component: () => import('@/views/Coupon.vue')
  //       },
  //       {
  //         path: "/addcoupon",
  //       id:"75",

  //         name: "添加优惠券",
          
  //         meta: {title: "添加优惠券", requireAuth: true},
  //         component: () => import('@/views/AddCoupon.vue')
  //       },
  //       {
  //         path:"/productdetailsactivity",
  //       id:"76",

  //         name:"商品活动管理",
  //         meta:{title:"商品详情活动",requireAuth:true,show:true},
  //         component:()=>import('@/views/Productdetailsactivity.vue')
  //       },{
  //         path:"/addproductdetailactivity",
  //       id:"77",

  //         name:"添加商品详情活动",
  //         meta:{title:"添加商品详情活动",requireAuth:true},
  //         component:()=>import('@/views/Addproductdetailactivity.vue')
  //       },
	// 	{
  //         path: "/merchantcoupon",
  //       id:"78",

  //         name: "商家券",

  //         meta: {title: '商家券', requireAuth: true, show: true},
  //         component: () => import('@/views/Merchantcoupon.vue')
  //       },
  //       {
  //         path: '/addmerchantcoupon',
  //       id:"79",

  //         name: '添加商家券',
          
  //         meta: {title: '添加商家券', requireAuth: true},
  //         component: () => import('@/views/Addmerchantcoupon.vue')
  //       }
  //   ]
  // },
  // {
  //   path: '/statistic',
  //   id:"80",

  //   component: Layout,
  //   name:'招商店铺数据管理',
  //   icon: "el-icon-star-off",
  //   meta: {title: constant.title, requireAuth: true},
    
  //   children: [
        
  //       {
  //         path: '/statistics',
  //       id:"81",

  //         name: '统计信息',
          
  //         meta: { title: '统计信息', requireAuth: true, channelid: '2' ,show: true},
  //         component: () => import("@/views/Statistics.vue"),
          
  //       },
  //       {
  //         path: '/addstatistics',
  //       id:"82",

  //         name: '添加统计信息',
  //         meta: { title: '添加统计信息', requireAuth: true ,show:true},
  //         component: () => import("@/views/Addstatistics.vue"),
          
  //       }
        
  //   ]
  // }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach((to,from,next)=>{
  console.log("to--");
  console.log(to);
  console.log(from);
  console.log(next);
  if(to.meta.title){
      document.title = to.meta.title
  }else{
      document.title = constant.title
  }
  let token = Cookies.get("token");
  console.log("token--" + token);
  let arr = Cookies.get('patharr');
  console.log("arr--" +arr+ Cookies.get('id'));
  console.log(to.name)
  if(to.name != "Login"){
    if(!token || token == 'undefined'){
      //没有登录过
      next({path:'/'})
    }else{
      next();
    }
  }else{
    next();
  }
})

export default router

